@font-face {
  font-family: "noto-sans-thai";
  src: url("./fonts/noto-sans-thai/NotoSansThai-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "noto-sans-thai";
  src: url("./fonts/noto-sans-thai/NotoSansThai-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "noto-sans";
  src: url("./fonts/noto-sans/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "noto-sans";
  src: url("./fonts/noto-sans/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kanit";
  src: url("./fonts/kanit/Kanit-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "kanit";
  src: url("./fonts/kanit/Kanit-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "noto-sans-thai", "kanit", "noto-sans", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
